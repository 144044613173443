const config = {
  api: {
    localhost: {
      baseUrl: 'http://localhost:5000/mari-a5cc7/us-central1',
    },
    'internal.getnofilter.com': {
      baseUrl: 'https://us-central1-mari-a5cc7.cloudfunctions.net',
    },
  },
  googleMaps: {
    apiKey: 'AIzaSyB7z8h8OiXfzFw43h-htolpA3hzcZe5aEE',
  },
  firebaseBaseUrl:
    // 'https://firebasestorage.googleapis.com/v0/b/mari-a5cc7.appspot.com/o/photos%2Fregular%2F',
    'https://statics.getnofilter.com/photos/regular/',
};

export default config;
