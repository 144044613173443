import OpenAI from 'openai';

const OPENAI_API_KEY = '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';

const openai = new OpenAI({
  apiKey: OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const cache = {};
const getTextV4 = async prompt => {
  if (cache[prompt]) {
    return cache[prompt];
  }

  cache[prompt] = openai.chat.completions
    .create({
      model: 'o1',
      messages: [
        {
          role: 'user',
          content: prompt,
        },
      ],
    })
    .then(r => r.choices[0].message.content.trim());

  return cache[prompt];
};

const actionsOpenai = {
  getTextV4,
};

export default actionsOpenai;
