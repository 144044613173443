import React, { useState, useEffect, Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import nearestPoint from '@turf/nearest-point';
import haversine from 'haversine-distance';
import { point as createPoint, featureCollection } from '@turf/helpers';
import actions from 'actions';
import Map from 'components/Map';
import Slider from 'components/Slider';
import PendingPromisses from 'components/PendingPromisses';
import SpotForm from './SpotForm';
import MainPhotoForm from './MainPhotoForm';
import TopPhotoForm from './TopPhotoForm';
import DescriptionForm from './DescriptionForm';
import EasyToAssign from './EasyToAssign';
import config from 'config';
import './styles.scss';

const TRANSLATION_TARGETS = [
  'ar',
  'az',
  'be',
  'ca',
  'cs',
  'de',
  'es',
  'fa',
  'fr',
  'hi',
  'hr',
  'hu',
  'id',
  'in',
  'is',
  'it',
  'ja',
  'ko',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sv',
  'th',
  'tr',
  'uk',
  'vi',
  'zh',
];

const ACTIONS = {
  NONE: 'NONE',
  SHOW_SPOT: 'SHOW_SPOT',
  CREATE: {
    SET_GPS: 'CREATE__SET_GPS',
    SPOT_FORM: 'CREATE__SPOT_FORM',
  },
  ASSIGN: 'ASSIGN',
  EDIT: 'EDIT',
};

const PHOTO_TYPE = {
  UNSPLASH: 'UNSPLASH',
  USER: 'USER',
  DETECTIVE: 'DETECTIVE',
};

const REJECTION_CAUSES = [
  { value: 0, label: 'Wrong GPS', 'data-action-key': 2 },
  { value: 1, label: 'Bad Quality' },
  { value: 2, label: 'Drone' },
  { value: 3, label: 'Too many photos from that spot' },
  { value: 4, label: 'Private' },
  { value: 5, label: 'Selfie' },
  { value: 6, label: 'Temporal' },
  { value: 7, label: 'Elieser' },
  { value: 8, label: 'Not a Spot', 'data-action-key': 3 },
  { value: 9, label: 'Already Approved' },
  { value: 10, label: 'Not Interesting Enough', 'data-action-key': 4 },
  { value: 11, label: 'Not Centerd' },
  { value: 12, label: 'Too Paronamic' },
  { value: 13, label: 'Too Much Edited' },
  { value: 14, label: 'Border Too Big' },
  { value: 15, label: 'Watermark Too Big' },
  { value: 99, label: 'Unknown' },
];

let listOfPossibleToAssign = [];

const Moderation = () => {
  const [photos, setPhotos] = useState([]);
  const [spots, setSpots] = useState([]);
  const [cityTours, setCityTours] = useState([]);
  const [userPhotos, setUserPhotos] = useState([]);

  // Selecteds
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedSpot, setSelectedSpot] = useState(null);
  const [selectedUserPhoto, setSelectedUserPhoto] = useState(null);

  //
  const [pointsToShow, setPointsToShow] = useState([]);

  const [action, setAction] = useState(ACTIONS.NONE);

  // Data used to Create and Edit a Spot
  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [from, setFrom] = useState('');
  const [slug, setSlug] = useState('');
  const [country, setCountry] = useState('');
  const [isGPSCorrect, setIsGPSCorrect] = useState(true);
  const [newGPS, setNewGPS] = useState({});

  // Data used to Assign photo to Spot
  const [assignTo, setAssignTo] = useState(null);

  // Data used to Reject
  const [cause, setCause] = useState('');

  // Data used for filters
  const [showPendings, setShowPendings] = useState(false);
  const [showPendingsFromUsers, setShowPendingsFromUsers] = useState(false);
  const [showRejecteds, setShowRejecteds] = useState(false);
  const [showSpots, setShowSpots] = useState(false);
  const [showNulls, setShowNulls] = useState(false);
  const [coors, setCoors] = useState({
    sw: {
      lat: 22,
      lng: -129,
    },
    ne: {
      lat: 50,
      lng: -66,
    },
  });
  const [isSettingSW, setIsSettingSW] = useState(false);
  const [isSettingNE, setIsSettingNE] = useState(false);

  // Others
  const [isCreating, setIsCreating] = useState(false);
  const [center, setCenter] = useState(undefined);
  const [zoom, setZoom] = useState(undefined);
  const [spotWithNoMainPhotoId, setSpotWithNoMainPhotoId] = useState(null);
  const [spotWithNoIsTop, setSpotWithNoIsTop] = useState(null);
  const [spotWithNoDescription, setSpotWithNoDescription] = useState(null);
  const [possibleToAssign, setPossibleToAssign] = useState(null);
  const [addresses, setAddresses] = useState(null);

  useEffect(() => {
    const _onKeyPress = e => {
      if (
        // ALWAYS CHECK THIS!
        document.querySelector('.TopPhotoForm') ||
        document.querySelector('.MainPhotoForm') ||
        document.querySelector('.DescriptionForm') ||
        document.querySelector('.EasyToAssign')
      ) {
        return;
      }

      if (e.key === 'Escape') {
        const button = document.querySelector('button.reject');

        if (button) {
          button.click();
        }
      } else {
        const actionButton = document.querySelector(`[data-action-key="${e.key}"]`);
        // event.metaKey is CMD (CMD + 3, for example)
        if (actionButton && !e.metaKey && actionButton.click) {
          e.preventDefault();
          actionButton.click();
        }
      }
    };

    (async () => {
      const { data } = await fetch(
        'https://storage.googleapis.com/mari-a5cc7.appspot.com/cityTours/index-v1.json?rand=' +
          Math.random()
      ).then(r => r.json());

      setCityTours(
        data.cityTours.map(x => ({
          ...x,
          type: 'CITYTOUR',
          pointType: 'citytour',
          lat: x.location.lat,
          lng: x.location.lng,
        }))
      );
    })();

    document.addEventListener('keydown', _onKeyPress, false);
    return () => {
      document.removeEventListener('keydown', _onKeyPress, false);
    };
  }, []);

  useEffect(() => {
    window.spots = spots;

    // if (selectedSpot) {
    //   setSelectedSpot(spots.find(x => x.id === selectedSpot.id));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spots]);

  useEffect(() => {
    window.userPhotos = userPhotos;

    // if (selectedUserPhoto) {
    //   setSelectedUserPhoto(userPhotos.find(x => x.id === selectedUserPhoto.id));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPhotos]);

  useEffect(() => {
    window.photos = photos;

    // if (selectedPhoto) {
    //   setSelectedPhoto(photos.find(x => x.id === selectedPhoto.id));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);

  const _getCloserPending = selectedPhoto => {
    const thePhotos = [
      ...photos.filter(x => x._status === 3),
      ...userPhotos.filter(x => x._status === 0),
    ]
      .filter(x => x.id !== selectedPhoto.id)
      .map(x => createPoint([x.lat, x.lng], { photo: x }));

    if (thePhotos.length === 0) {
      return null;
    }

    const photoPoints = featureCollection(thePhotos);

    const centerPoint = createPoint([selectedPhoto.lat, selectedPhoto.lng]);
    const closer = nearestPoint(centerPoint, photoPoints);
    return closer;
  };

  const _moveToNextPhoto = lastPhoto => {
    if (!lastPhoto) {
      reset();
      return;
    }

    // Get next photo pending
    const nextPhotoPoint = _getCloserPending(lastPhoto);

    if (!nextPhotoPoint || !nextPhotoPoint.properties || !nextPhotoPoint.properties.photo) {
      reset();
      return;
    }

    const nextPhoto = nextPhotoPoint.properties.photo;

    // Simulate click on it
    _onPointClick(nextPhoto);

    // Move Map
    setCenter({
      lat: nextPhoto.lat,
      lng: nextPhoto.lng,
    });
  };

  const reset = (callback = () => {}) => {
    if (document.querySelector("button[title='Close']")) {
      document.querySelector("button[title='Close']").click();
    }

    setSelectedSpot(null);
    setSelectedPhoto(null);
    setSelectedUserPhoto(null);

    setId(null);
    setName('');
    setFrom('');
    setSlug('');
    setCountry('');
    setIsGPSCorrect(true);
    setNewGPS({});
    setAssignTo(null);
    setCause('');

    setAction(ACTIONS.NONE);

    setAddresses(null);

    if (typeof callback === 'function') {
      callback();
    }
  };

  const getUnsplashPhotosPending = async () => {
    const hydrateds = await actions.photos.getHydratedsWithLocation({
      limit: 10000,
      coors,
    });

    const photos = hydrateds
      .filter(
        x =>
          coors.ne.lat > x.location.position.latitude && coors.sw.lat < x.location.position.latitude
      )
      .map(x => {
        const photo = {
          ...x,
          lat: x.location.position.latitude,
          lng: x.location.position.longitude,
          type: PHOTO_TYPE.UNSPLASH,
          pointType: 'photo',
        };

        return photo;
      });

    setPhotos(photos);

    checkIfEasyToAssign(userPhotos, photos, spots);
  };

  const getDetectivePhotosPending = async () => {
    const { photos: allPhotos } = await actions.photos.detective.getReadys();

    const photos = allPhotos.map(x => {
      const photo = {
        ...x,
        lat: x.location.position.latitude,
        lng: x.location.position.longitude,
        type: PHOTO_TYPE.UNSPLASH,
        pointType: 'photo',
        detective: x.detective,
      };

      return photo;
    });

    setPhotos(photos);

    checkIfEasyToAssign(userPhotos, photos, spots);
  };

  const getUserPhotosPending = async () => {
    const hydrateds = await actions.userPhotos.getHydratedsWithLocation({
      limit: 10000,
    });

    const userPhotos = hydrateds.map(x => {
      const photo = {
        ...x,
        lat: x.location.lat,
        lng: x.location.lng,
        type: PHOTO_TYPE.USER,
        pointType: 'userPhoto',
      };

      return photo;
    });

    setUserPhotos(userPhotos);
  };

  const getSpots = async () => {
    const spots = (await actions.spots.getAllLight()).map(x => ({
      ...x,
      pointType: 'spot',
      // This is just to use from Developers Console
      goThere: () => {
        setCenter({ lat: x.lat, lng: x.lng });
      },
    }));

    setSpots(spots);

    setSpotWithNoMainPhotoId(spots.find(x => !x.mainPhotoId));
    setSpotWithNoDescription(
      spots.find(x => typeof x.description === 'undefined' || x.description === null)
    );

    checkIfEasyToAssign(userPhotos, photos, spots);

    // Request some translations
    let translated = 0;

    for (const spot of spots) {
      if (!spot.description || !spot.description.en) {
        continue;
      }

      const descriptionsReady = Object.keys(spot.description);

      const isSomeMissing = TRANSLATION_TARGETS.some(target => !descriptionsReady.includes(target));

      if (isSomeMissing) {
        setTimeout(() => {
          actions.spots.translateDescriptionById(spot.id);
        }, 2000 * translated);

        translated++;
      }

      if (translated > 450 / 2) {
        return;
      }
    }

    if (translated === 0) {
      // Todo listo. No decimos nada.
    } else if (translated < 450 / 2) {
      window.alert('Lista la traduccion!');
    }
  };

  async function checkIfEasyToAssign(userPhotos, photos, spots) {
    try {
      const proms = [...userPhotos, ...photos].map(async photo => {
        let nearSpots = [];

        spots.forEach(spot => {
          // Compute distance from the main photo or user's photo
          let distance = haversine(
            photo.type === PHOTO_TYPE.UNSPLASH ? photo.location.position : photo.location,
            spot
          );

          // If there are detective points, check and keep the shortest distance
          if (photo.detective) {
            photo.detective.forEach(detective => {
              distance = Math.min(distance, haversine(detective.position, spot));
            });
          }

          if (distance < 300) {
            nearSpots.push({ ...spot, distance });
          }
        });

        if (nearSpots.length > 0) {
          nearSpots.sort((a, b) => a.distance - b.distance);

          const proms = nearSpots.map(async spot => {
            await hydrateSpot(spot);
          });

          await Promise.all(proms);

          listOfPossibleToAssign.push({ photo, spots: nearSpots });
        }
      });

      await Promise.all(proms);

      if (listOfPossibleToAssign.length > 0) {
        setSelectedPhoto(null);
        setSelectedUserPhoto(null);

        setPossibleToAssign(listOfPossibleToAssign[0]);
        listOfPossibleToAssign = listOfPossibleToAssign.slice(1);
      }
    } catch (error) {
      console.error(error);
      window.alert(error.message);
    }
  }

  const _onPointHover = photo => {
    if (photo.urls && photo.urls.regular) {
      new Image().src = photo.urls.regular
        .replace('.jpg', '.webp')
        .replace(
          'https://storage.googleapis.com/mari-a5cc7.appspot.com',
          'https://statics.getnofilter.com'
        );
    } else if (photo.url) {
      new Image().src = photo.url
        .replace('.jpg', '.webp')
        .replace(
          'https://storage.googleapis.com/mari-a5cc7.appspot.com',
          'https://statics.getnofilter.com'
        );
    } else {
      console.log('$$ photo with no hover', photo);
    }
  };

  const _onPointClick = photo => {
    console.info('Photo Clicked', photo);

    reset(() => {
      setNewGPS({ lat: photo.lat, lng: photo.lng });

      if (photo.type === PHOTO_TYPE.UNSPLASH) {
        setSelectedPhoto(photo);
        setSelectedUserPhoto(null);

        // if (photo.location && photo.location.name) {
        //   setName(photo.location.name.split(',')[0]);
        // }
      } else if (photo.type === PHOTO_TYPE.USER) {
        setSelectedUserPhoto(photo);
        setSelectedPhoto(null);

        setName((photo.name || '').trim());
        setFrom((photo.from || '').trim());

        // precache for the app
        const tempImg = new Image();
        tempImg.src = photo.url
          .replace('.jpg', '.webp')
          .replace('/regular/', '/small/')
          .replace(
            'https://storage.googleapis.com/mari-a5cc7.appspot.com',
            'https://statics.getnofilter.com'
          );
      } else if (photo.type === PHOTO_TYPE.DETECTIVE) {
        console.log('$$ NO ACTION DEFINED YET', photo);
      }
    });
  };

  const hydrateSpot = async spot => {
    if (spot.isHydrated) {
      return;
    }

    const { spot: fullSpot } = await actions.spots.getHydratedById(spot.id);

    Object.assign(spot, fullSpot, { isHydrated: true });

    // const newSpot = { ...spot, ...fullSpot, isHydrated: true };

    setSpots(spots => spots.map(x => (x.id === spot.id ? spot : x)));

    if (selectedSpot && spot.id === selectedSpot.id) {
      setSelectedSpot(spot);
    }
  };

  function checkIfShouldRevalidateUser(photo) {
    if (photo.type === PHOTO_TYPE.USER) {
      const noMorePhotosOfThisUser =
        userPhotos.filter(x => x._status === 0 && x.user.id === photo.user.id).length === 1;

      if (noMorePhotosOfThisUser) {
        const options = ['epe', 'cre', 'tal'];
        const PUBLIC_KEY = '164572184cd73d0bfd9b5a4bed709d01';
        const url = `https://getnofilter.com/api/revalidate?se${options[1]}t=${PUBLIC_KEY}&username=${photo.user.username}`;

        fetch(url).catch(() => {
          window.open(url);
        });
      }
    }
  }

  const _onSpotClick = async spot => {
    await hydrateSpot(spot);

    console.info('Spot Clicked', spot);

    if (action === ACTIONS.ASSIGN) {
      setAssignTo(spot);
    } else {
      setAction(ACTIONS.SHOW_SPOT);
    }

    setSelectedSpot(spot);

    setTimeout(() => {
      document.querySelector('.Map button.approve').focus();
    }, 0);
  };

  const _onMapChange = ({ zoom, center }) => {
    setZoom(zoom);
    setCenter(center);
  };

  const _onReject = () => {
    const photo = selectedPhoto || selectedUserPhoto;
    const nextPhotoToClick = cloneDeep(photo);

    if (photo.type === PHOTO_TYPE.UNSPLASH) {
      // Not necessary to wait here
      actions.photos.moderation.reject({
        id: photo.id,
        cause,
      });

      setSelectedPhoto(null);

      setPhotos(photos => photos.map(x => (x.id === photo.id ? { ...x, _status: 6 } : x)));
    } else {
      const causeId = Number(cause);

      if (Number.isNaN(causeId)) {
        alert('Cause must be a number');
        return;
      }

      // Not necessary to wait here
      actions.userPhotos.reject({
        ...photo,
        cause: causeId,
      });

      setSelectedUserPhoto(null);

      checkIfShouldRevalidateUser(photo);

      setUserPhotos(userPhotos =>
        userPhotos.map(x => (x.id === photo.id ? { ...x, _status: 2 } : x))
      );
    }

    _moveToNextPhoto(nextPhotoToClick);
  };

  const _onAssign = async (_photo, _spot) => {
    setIsCreating(true);

    const photo = _photo || selectedPhoto || selectedUserPhoto;
    const nextPhotoToClick = cloneDeep(photo);
    const spot = _spot || selectedSpot;

    if (!spot.isHydrated) {
      window.alert('Falta hidratar');
      return;
    }

    const newPhoto = {
      ...photo,
      _status: photo.type === PHOTO_TYPE.UNSPLASH ? 5 : 1,
    };

    let newSpot = {
      ...spot,
    };

    if (photo.type === PHOTO_TYPE.UNSPLASH) {
      newSpot.photos = [...(spot.photos || []), photo.id];
      newSpot.photosHydrated = [...(spot.photosHydrated || []), photo];
    } else {
      newSpot.userPhotos = [...(spot.userPhotos || []), photo.id];
      newSpot.userPhotosHydrated = [...(spot.userPhotosHydrated || []), photo];
    }

    checkIfShouldRevalidateUser(photo);

    if (photo.type === PHOTO_TYPE.UNSPLASH) {
      setPhotos(photos => photos.map(x => (x.id === photo.id ? newPhoto : x)));
    } else {
      setUserPhotos(userPhotos => userPhotos.map(x => (x.id === photo.id ? newPhoto : x)));
    }

    setSelectedPhoto(null);
    setSelectedUserPhoto(null);

    setSpots(spots => spots.map(x => (x.id === spot.id ? newSpot : x)));
    setSelectedSpot(null);
    setSpotWithNoMainPhotoId(newSpot);

    _moveToNextPhoto(nextPhotoToClick);

    let proms = [];

    const p = actions.spots.assign(spot, newPhoto);
    actions.promises.add(p, 'short'); // small delay, because no revlidate, but new files
    proms.push(p);

    // We have to wait for P because `.approve` will try to get the assign data in order
    // to send notifications and add points for the detective.
    await p;

    if (photo.type === PHOTO_TYPE.UNSPLASH) {
      proms.push(actions.photos.moderation.approve(photo));
    } else {
      proms.push(actions.userPhotos.approve(photo, spot));
    }

    Promise.all(proms)
      .then(() => {
        //
      })
      .catch(error => {
        window.alert('Error en assign!: ' + error.message);
        console.log('$$ error', error, spot, photo);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const _onSpotFormChange = data => {
    setName(data.name);
    setFrom(data.from);
    setSlug(data.slug);
    setCountry(data.country);
  };

  const _onCreateNewSpotClick = () => {
    setAction(ACTIONS.CREATE.SET_GPS);
    setIsGPSCorrect(false);
  };

  const _onRejectDrone = () => {
    setCause('Drone');
    _onReject();
  };

  const _onRejectBlah = () => {
    setCause('Blah');
    _onReject();
  };

  const _onRejectWrongGPS = () => {
    setCause('Wrong GPS');
    _onReject();
  };

  const _onGPSSet = () => {
    setAction(ACTIONS.CREATE.SPOT_FORM);
    setIsGPSCorrect(false);
  };

  const _onCreateSpotClick = () => {
    const photo = selectedPhoto || selectedUserPhoto;
    const nextPhotoToClick = cloneDeep(photo);

    if (!country) {
      window.alert('Missing country');
      return;
    }

    if (!slug) {
      window.alert('Missing slug');
      return;
    }

    setIsCreating(true);

    let spot = {
      id: null,
      name,
      from,
      slug,
      country,
      lat: null,
      lng: null,
      photos: [],
      userPhotos: [],
      isTop: false,
      mainPhotoId: photo.id,
    };

    if (photo.type === PHOTO_TYPE.UNSPLASH) {
      spot.photos = [photo.id];
    } else {
      spot.userPhotos = [photo.id];
    }

    if (isGPSCorrect) {
      if (photo.type === PHOTO_TYPE.UNSPLASH) {
        spot.lat = photo.location.position.latitude;
        spot.lng = photo.location.position.longitude;
      } else {
        spot.lat = photo.location.lat;
        spot.lng = photo.location.lng;
      }
    } else {
      spot.lat = newGPS.lat;
      spot.lng = newGPS.lng;
    }

    actions.spots
      .create(spot)
      .then(newSpot => {
        if (photo.type === PHOTO_TYPE.UNSPLASH) {
          newSpot.photosHydrated = [photo];
          newSpot.userPhotosHydrated = [];
          actions.photos.moderation.approve(photo).catch(err => {
            console.log('$$ actions.photos.moderation.approve error', err);
            window.alert('Error Approving unsplash photo: ' + err.message);
          });
        } else {
          newSpot.photosHydrated = [];
          newSpot.userPhotosHydrated = [photo];
          actions.userPhotos.approve(photo, newSpot).catch(err => {
            console.log('$$ userPhotos.approve error', err);
            window.alert('Error Approving user photo and sending notification: ' + err.message);
          });
        }

        newSpot.pointType = 'spot';

        setSpots(spots => [...spots, newSpot]);

        if (photo.type === PHOTO_TYPE.UNSPLASH) {
          setPhotos(photos => [...photos.map(x => (x.id === photo.id ? { ...x, _status: 5 } : x))]);
          setSelectedPhoto(null);
        }

        checkIfShouldRevalidateUser(photo);

        if (photo.type === PHOTO_TYPE.USER) {
          setUserPhotos(userPhotos => [
            ...userPhotos.map(x => (x.id === photo.id ? { ...x, _status: 1 } : x)),
          ]);
          setSelectedUserPhoto(null);
        }

        setSpotWithNoIsTop(newSpot);
        setSpotWithNoDescription(newSpot);

        _moveToNextPhoto(nextPhotoToClick);
      })
      .catch(error => {
        window.alert('Error!: ' + error.message);
        console.log('$$ error', error, spot, photo);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const getAddresses = async (lat, lng) => {
    const r = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=en&key=${config.googleMaps.apiKey}`
    );
    const json = await r.json();
    setAddresses(json.results);
  };

  const _onMapClick = ({ lat, lng, ...other }) => {
    // it could be `null`, array, or `true`
    if (addresses === true) {
      getAddresses(lat, lng);
    } else if (action === ACTIONS.CREATE.SET_GPS || action === ACTIONS.EDIT) {
      setNewGPS({ lat, lng });
    } else if (isSettingSW) {
      setCoors({
        ...coors,
        sw: {
          lat,
          lng,
        },
      });

      setIsSettingNE(false);
      setIsSettingSW(false);
    } else if (isSettingNE) {
      setCoors({
        ...coors,
        ne: {
          lat,
          lng,
        },
      });

      setIsSettingNE(false);
      setIsSettingSW(false);
    }
  };

  useEffect(() => {
    const pointsToShow = [...cityTours];

    if (showPendings) {
      if (showNulls) {
        pointsToShow.push(...photos.filter(x => x._status === 3));
      } else {
        pointsToShow.push(...photos.filter(x => x.lat !== null && x._status === 3));
      }
    }

    if (showPendingsFromUsers) {
      pointsToShow.push(...userPhotos.filter(x => x._status === 0));
    }

    if (showRejecteds) {
      pointsToShow.push(...userPhotos.filter(x => x._status === 2));
    }

    if (showSpots) {
      pointsToShow.push(...spots);
    }

    const isSettingGPS = action === ACTIONS.CREATE.SET_GPS;
    const isCreatingSpotWithWrongGPS = !isGPSCorrect && action === ACTIONS.CREATE.SPOT_FORM;
    const isEditing = action === ACTIONS.EDIT;

    if (isSettingGPS || isCreatingSpotWithWrongGPS || isEditing) {
      pointsToShow.push({ ...newGPS, pointType: 'crosshair' });
    }

    setPointsToShow(pointsToShow);
  }, [
    action,
    spots,
    userPhotos,
    photos,
    showPendings,
    showSpots,
    showNulls,
    showPendingsFromUsers,
    showRejecteds,
    isGPSCorrect,
    newGPS,
    cityTours,
  ]);

  const onPendingsFilterClick = async () => {
    if (photos.length === 0) {
      await getUnsplashPhotosPending();
    }

    setShowPendings(!showPendings);
  };

  const onPendingsFromUsersFilterClick = async () => {
    if (userPhotos.length === 0) {
      await getUserPhotosPending();
    }

    setShowPendingsFromUsers(!showPendingsFromUsers);
  };

  const onDetectiveFilterClick = async () => {
    if (photos.length === 0) {
      await getDetectivePhotosPending();
    }

    setShowPendings(!showPendings);
  };

  // const onRejectedsFilterClick = async () => {
  //   if (photos.length === 0) {
  //     await getUnsplashPhotosPending();
  //   }

  //   setShowRejecteds(!showRejecteds);
  // };

  const onSpotsFilterClick = async () => {
    if (spots.length < 20) {
      await getSpots();
    }

    setShowSpots(!showSpots);
  };

  const onCoorsChange = newData => {
    setCoors({ ...coors, ...newData });
    setPhotos([]);
  };

  const _onEditSpotClick = () => {
    const spot = selectedSpot;

    setId(spot.id);
    setName(spot.name);
    setFrom(spot.from);
    setSlug(spot.slug);
    setCountry(spot.country);
    setIsGPSCorrect(false);
    setNewGPS({
      lat: spot.lat,
      lng: spot.lng,
    });
    setAction(ACTIONS.EDIT);
  };

  const _onCreateCityToutClick = async () => {
    const { lat, lng } = center;

    const r = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=locality&language=en&key=${config.googleMaps.apiKey}`
    );
    const json = await r.json();
    const parts = json.results[json.results.length - 1].address_components;

    const locality = (parts.find(x => x.types.includes('locality')) || parts[0]).long_name;
    const country = parts.find(x => x.types.includes('country')).long_name;

    const city = `${locality}, ${country}`;

    if (!city) {
      window.alert('Error');
      console.log(json);
      return;
    }

    if (!window.confirm(`${city}?`)) {
      return;
    }

    setCityTours([
      ...cityTours,
      {
        id: Math.random(),
        city: {
          en: city,
        },
        lat: Number(center.lat.toFixed(4)),
        lng: Number(center.lng.toFixed(4)),
        type: 'CITYTOUR',
        pointType: 'citytour',
      },
    ]);

    actions.cityTours.add({
      city: {
        en: city,
      },
      location: {
        lat: Number(center.lat.toFixed(4)),
        lng: Number(center.lng.toFixed(4)),
      },
    });
  };

  const _onSaveSpotClick = () => {
    const spot = selectedSpot;
    const newData = {
      name,
      from,
      slug,
      country,
      lat: newGPS.lat,
      lng: newGPS.lng,
    };

    setIsCreating(true);
    const p = actions.spots
      .edit(spot, newData)
      .then(() => {
        setIsCreating(false);
      })
      .catch(error => {
        console.error(error);
        window.alert('Error editing!: ' + error.message);
      });

    actions.promises.add(p, 'long');

    setSpots(spots => spots.map(x => (x.id === spot.id ? { ...spot, ...newData } : x)));

    reset();
  };

  const onMainPhotoIdChange = ({ spotId, mainPhotoId }) => {
    setSpots(spots => {
      spots.find(x => x.id === spotId).mainPhotoId = mainPhotoId;
      return [...spots];
    });
    setSpotWithNoMainPhotoId(null);
  };

  const onIsTopPhotoChange = ({ spotId, isTop }) => {
    setSpots(spots => {
      spots.find(x => x.id === spotId).isTop = isTop;
      return [...spots];
    });
    setSpotWithNoIsTop(null);
  };

  const onDescriptionChange = ({ spotId, description }) => {
    setSpots(spots => {
      spots.find(x => x.id === spotId).description = description;
      return [...spots];
    });

    // TODO: Replace this with `null`
    setSpotWithNoDescription(
      spots.find(x => typeof x.description === 'undefined' || x.description === null)
    );
  };

  const _NeedsMoreInfo = async photo => {
    try {
      if (photo.type === PHOTO_TYPE.UNSPLASH) {
        actions.photos.editById(photo.id, { detectiveNeedsMoreData: true });
        setPhotos(photos.filter(x => x.id !== photo.id));
        setSelectedPhoto(null);
      } else {
        setUserPhotos(userPhotos.filter(x => x.id !== photo.id));
        setSelectedUserPhoto(null);
      }

      _moveToNextPhoto(photo);
    } catch (error) {
      window.alert('Error on detectiveNeedsMoreInfo: ' + error.message);
    }
  };

  const photo = selectedPhoto || selectedUserPhoto;
  const spot = selectedSpot;
  const isUnsplash = photo && photo.type === PHOTO_TYPE.UNSPLASH;

  let mapActions = [];

  if (photo && action === ACTIONS.NONE) {
    mapActions = [
      <button
        key={0}
        autoFocus
        data-action-key="1"
        className="approve"
        onClick={_onCreateNewSpotClick}
      >
        CREATE (1)
      </button>,
      <button
        key={1}
        data-action-key="2"
        className="approve"
        onClick={async () => {
          // const visibleSpots = document.querySelectorAll('.Map .SpotPoint');
          // const onlyOne = visibleSpots.length === 1;
          // setAction(ACTIONS.ASSIGN);
          // setTimeout(() => {
          //   visibleSpots[0].click();
          // }, 10);

          let nearSpots = [];

          spots.forEach(spot => {
            let distance = haversine(
              photo.type === PHOTO_TYPE.UNSPLASH ? photo.location.position : photo.location,
              spot
            );

            if (photo.detective) {
              photo.detective.forEach(detective => {
                distance = Math.min(distance, haversine(detective.position, spot));
              });
            }

            if (distance < 300) {
              nearSpots.push({ ...spot, distance });
            }
          });

          if (nearSpots.length > 0) {
            nearSpots.sort((a, b) => a.distance - b.distance);

            const proms = nearSpots.map(async spot => {
              await hydrateSpot(spot);
            });

            await Promise.all(proms);

            setPossibleToAssign({ photo, spots: nearSpots });
          } else {
            setAction(ACTIONS.ASSIGN);
          }
        }}
      >
        ASSIGN (2)
      </button>,
      <button
        key={2}
        data-action-key="3"
        className="reject"
        onClick={() => setAction(ACTIONS.REJECT)}
      >
        REJECT (3)
      </button>,
      <button key={3} data-action-key="4" className="reject" onClick={() => _NeedsMoreInfo(photo)}>
        NEEDS INFO (4)
      </button>,
    ];
  } else if (spot && ![ACTIONS.NONE, ACTIONS.EDIT, ACTIONS.CREATE.SPOT_FORM].includes(action)) {
    mapActions = [
      <button key={0} data-action-key="1" className="approve" onClick={_onEditSpotClick}>
        EDIT (1)
      </button>,
    ];
  } else {
    mapActions = [
      <button key={0} className="approve" onClick={_onCreateCityToutClick}>
        CREATE CITY TOUR
      </button>,
    ];
  }

  return (
    <div className="Moderation">
      <div className={`left ` + (action !== ACTIONS.NONE ? '--showingForm' : '')}>
        {!!photo && action === ACTIONS.NONE && (
          <Fragment>
            <img
              className="photo"
              src={(isUnsplash ? photo.urls.regular : photo.url)
                .replace('.jpg', '.webp')
                .replace(
                  'https://storage.googleapis.com/mari-a5cc7.appspot.com',
                  'https://statics.getnofilter.com'
                )}
              data-id={photo.id}
              alt="Selected"
            />
            <div className="photo-description">
              {isUnsplash && (
                <Fragment>
                  {photo.description && <div>Description: {photo.description}</div>}
                  <div>
                    Location: [{photo.location.name}] [{photo.location.title}]
                  </div>
                </Fragment>
              )}

              {!isUnsplash && <div>{photo.user.username}</div>}
            </div>
          </Fragment>
        )}

        {!!spot && action === ACTIONS.SHOW_SPOT && (
          <div className="slider">
            <div className="quantity">
              {spot.photosHydrated.length} unsplash photos, {spot.userPhotosHydrated.length} user
              photos
            </div>

            <Slider
              key={spot.id}
              photos={[
                ...spot.userPhotosHydrated.map(x => x.url),
                ...spot.photosHydrated.map(x => x.urls.regular),
              ]}
            />
          </div>
        )}

        {action === ACTIONS.CREATE.SET_GPS && (
          <div className="gps-set">
            <h1>Click on the map to set the new GPS position</h1>
            <div>
              GPS point: Lat {newGPS.lat} Lng {newGPS.lng}
            </div>
            <button autoFocus data-action-key="1" className="approve" onClick={_onGPSSet}>
              Done (1)
            </button>
          </div>
        )}

        {action === ACTIONS.CREATE.SPOT_FORM && (
          <div className="create-spot">
            <SpotForm
              spots={spots}
              onChange={_onSpotFormChange}
              onSubmit={() => {
                setTimeout(() => {
                  document.querySelector('button.approve').focus();
                }, 0);
              }}
              id={id}
              name={name}
              from={from}
              slug={slug}
              country={country}
              lat={newGPS.lat}
              lng={newGPS.lng}
              photo={selectedPhoto || selectedUserPhoto}
            />

            {!!photo && (
              <div>
                {photo.description && <div>Description: {photo.description}</div>}

                {(!!photo.location.name || !!photo.location.title) && (
                  <div>
                    Location: [{photo.location.name}] [{photo.location.title}]
                  </div>
                )}

                {Array.isArray(addresses) && (
                  <div>
                    {[...new Set(addresses.map(x => x.formatted_address))].map(x => (
                      <div>{x}</div>
                    ))}
                  </div>
                )}

                {(Array.isArray(addresses) || addresses === null) && (
                  <button data-action-key="9" onClick={() => setAddresses(true)}>
                    Addresses (9)
                  </button>
                )}

                {addresses === true && <div>Click on the map to get the address</div>}

                {photo.detective &&
                  photo.detective.map(item => (
                    <div key={item.whoId} style={{ marginTop: '8px' }}>
                      Name: {item.name}, from: {item.from} [DELETE]
                    </div>
                  ))}
              </div>
            )}

            <button style={{ marginTop: '16px' }} className="reject" onClick={() => reset()}>
              Cancel
            </button>

            <button
              autoFocus={!!name && !!from}
              className="approve"
              disabled={isCreating}
              onClick={_onCreateSpotClick}
            >
              {isCreating ? 'Creating...' : 'Create'}
            </button>

            <br />

            {!!photo && (
              <img
                className="photo"
                src={(photo.type === PHOTO_TYPE.UNSPLASH ? photo.urls.regular : photo.url)
                  .replace('.jpg', '.webp')
                  .replace(
                    'https://storage.googleapis.com/mari-a5cc7.appspot.com',
                    'https://statics.getnofilter.com'
                  )}
                data-id={photo.id}
                alt="Selected"
              />
            )}
          </div>
        )}

        {action === ACTIONS.ASSIGN && (
          <div className="assign-form">
            <div>The photo is going to be assigned to:</div>

            {assignTo ? (
              <div>
                Spot Name: "{assignTo.name}" from "{assignTo.from}"
              </div>
            ) : (
              <div>Select a Spot</div>
            )}

            {assignTo && (
              <button
                data-action-key="1"
                disabled={isCreating}
                className="assign"
                onClick={() => _onAssign()}
              >
                Yes, Assign it (1)
              </button>
            )}

            <button
              data-action-key="2"
              className="reject"
              onClick={() =>
                reset(() => {
                  setSelectedPhoto(selectedPhoto);
                  setSelectedUserPhoto(selectedUserPhoto);
                })
              }
            >
              Cancel (2)
            </button>

            <br />
            <br />

            {assignTo && (
              <>
                {(assignTo.photosHydrated || []).map(x => (
                  <img
                    className="assign_photo"
                    key={x.id}
                    data-id={x.id}
                    src={x.urls.regular}
                    alt={assignTo.name}
                  />
                ))}

                {(assignTo.userPhotosHydrated || []).map(x => (
                  <img
                    className="assign_photo"
                    key={x.id}
                    data-id={x.id}
                    src={x.url}
                    alt={assignTo.name}
                  />
                ))}
              </>
            )}
          </div>
        )}

        {action === ACTIONS.REJECT && isUnsplash && (
          <div className="reject-form">
            <textarea value={cause} onChange={e => setCause(e.target.value)} placeholder="Cause" />
            <button className="reject" onClick={_onReject}>
              Reject
            </button>

            <br />
            <br />
            <br />
            <br />

            <button data-action-key="2" className="reject" onClick={_onRejectWrongGPS}>
              Wrong GPS (2)
            </button>
            <br />
            <br />
            <button data-action-key="3" className="reject" onClick={_onRejectBlah}>
              Blah (3)
            </button>
            <br />
            <br />
            <button className="reject" onClick={_onRejectDrone}>
              Drone
            </button>
          </div>
        )}

        {action === ACTIONS.REJECT && !isUnsplash && (
          <div className="reject-form">
            {REJECTION_CAUSES.map(x => (
              <button
                key={x.value}
                className={cause === x.value ? 'approve' : 'reject'}
                data-action-key={x['data-action-key']}
                onClick={() => setCause(x.value)}
              >
                {x.label}
                {x['data-action-key'] && ` (${x['data-action-key']})`}
              </button>
            ))}

            <br />
            <br />

            <button className="reject" data-action-key="Enter" onClick={_onReject}>
              Reject
            </button>
          </div>
        )}

        {action === ACTIONS.EDIT && (
          <div className="edit-spot-form">
            <SpotForm
              spots={spots}
              onChange={_onSpotFormChange}
              onSubmit={() => {
                setTimeout(() => {
                  document.querySelector('button.approve').focus();
                }, 0);
              }}
              id={id}
              name={name}
              from={from}
              slug={slug}
              country={country}
              lat={newGPS.lat}
              lng={newGPS.lng}
              photo={selectedPhoto || selectedUserPhoto}
            />

            {newGPS.lat && (
              <div>
                New GPS point: Lat {newGPS.lat} Lng {newGPS.lng}
              </div>
            )}

            <button className="reject" onClick={reset}>
              Cancel
            </button>
            <button className="approve" onClick={_onSaveSpotClick}>
              Save
            </button>
          </div>
        )}
      </div>

      <div className="right">
        <div className="filters">
          <input type="checkbox" checked={showPendings} onChange={onPendingsFilterClick} /> Unsplash
          <input
            type="checkbox"
            checked={showPendingsFromUsers}
            onChange={onPendingsFromUsersFilterClick}
          />{' '}
          Users
          <input type="checkbox" checked={showPendings} onChange={onDetectiveFilterClick} />{' '}
          Detective
          {/* <input type="checkbox" checked={showRejecteds} onChange={onRejectedsFilterClick} />{' '}
          Rejected */}
          <input type="checkbox" checked={showSpots} onChange={onSpotsFilterClick} /> Spots
          {/* <input
            type="checkbox"
            checked={showNulls}
            onChange={() => setShowNulls(!showNulls)}
          />{' '}
          Nulls */}
          {' | '}
          <span onClick={() => setIsSettingSW(true)}>SW</span>:
          <input
            className="coor"
            type="text"
            onChange={e => onCoorsChange({ sw: { lat: e.target.value, lng: coors.sw.lng } })}
            value={coors.sw.lat}
          />
          <input
            className="coor"
            type="text"
            onChange={e => onCoorsChange({ sw: { lat: coors.sw.lat, lng: e.target.value } })}
            value={coors.sw.lng}
          />{' '}
          | <span onClick={() => setIsSettingNE(true)}>NE</span>:
          <input
            className="coor"
            type="text"
            onChange={e => onCoorsChange({ ne: { lat: +e.target.value, lng: coors.ne.lng } })}
            value={coors.ne.lat}
          />
          <input
            className="coor"
            type="text"
            onChange={e => onCoorsChange({ ne: { lat: coors.ne.lat, lng: +e.target.value } })}
            value={coors.ne.lng}
          />
        </div>

        <div className="map">
          <Map
            selectedPhoto={selectedPhoto || selectedUserPhoto || {}}
            selectedSpot={selectedSpot || {}}
            points={pointsToShow}
            cityTours={cityTours}
            spots={showSpots ? spots : []}
            center={center}
            zoom={zoom}
            onChange={_onMapChange}
            onClick={_onMapClick}
            actions={mapActions}
            onPointClick={_onPointClick}
            onPointHover={_onPointHover}
            onSpotClick={_onSpotClick}
            hideSpots={
              [ACTIONS.CREATE.SPOT_FORM, ACTIONS.EDIT].includes(action) ||
              spotWithNoMainPhotoId ||
              spotWithNoIsTop ||
              spotWithNoDescription ||
              possibleToAssign
            }
          />
        </div>
      </div>

      {spotWithNoMainPhotoId && (
        <MainPhotoForm
          spot={spotWithNoMainPhotoId}
          onChange={onMainPhotoIdChange}
          onClose={() => setSpotWithNoMainPhotoId(null)}
        />
      )}

      {!spotWithNoMainPhotoId && spotWithNoIsTop && (
        <TopPhotoForm
          key={spotWithNoIsTop.id}
          spot={spotWithNoIsTop}
          onChange={onIsTopPhotoChange}
          onClose={() => setSpotWithNoIsTop(null)}
        />
      )}

      {!spotWithNoMainPhotoId && !spotWithNoIsTop && spotWithNoDescription && (
        <DescriptionForm
          key={spotWithNoDescription.id}
          spot={spotWithNoDescription}
          onChange={onDescriptionChange}
        />
      )}

      {!spotWithNoMainPhotoId && !spotWithNoIsTop && !spotWithNoDescription && possibleToAssign && (
        <EasyToAssign
          key={possibleToAssign.photo.id}
          photo={possibleToAssign.photo}
          spots={possibleToAssign.spots}
          onYes={spot => {
            _onAssign(possibleToAssign.photo, spot);

            if (listOfPossibleToAssign.length > 0) {
              setPossibleToAssign(listOfPossibleToAssign[0]);
              listOfPossibleToAssign = listOfPossibleToAssign.slice(1);
            } else {
              setPossibleToAssign(null);
            }
          }}
          onNo={() => {
            if (listOfPossibleToAssign.length > 0) {
              setPossibleToAssign(listOfPossibleToAssign[0]);
              listOfPossibleToAssign = listOfPossibleToAssign.slice(1);
            } else {
              setPossibleToAssign(null);
              setAction(ACTIONS.ASSIGN);
            }
          }}
        />
      )}

      <div className="pendingPromisses">
        <PendingPromisses />
      </div>
    </div>
  );
};

export default Moderation;
