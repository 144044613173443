let proms = [];

const add = (prom, delayType) => {
  let delay = 0;

  if (delayType === 'short') {
    delay = 2000;
  } else if (delayType === 'long') {
    delay = 4500;
  }

  prom.finally(() => {
    setTimeout(() => {
      prom.isResolved = true;
    }, delay);
  });

  proms.push(prom);
};

const getAll = () => {
  proms = proms.filter(x => !x.isResolved);
  return proms;
};

const actionsTips = {
  add,
  getAll,
};

export default actionsTips;
