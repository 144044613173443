import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMap from 'google-map-react';
import ColorPoint from './Points/ColorPoint';
import SpotPoint from './Points/SpotPoint';
import CrosshairPoint from './Points/CrosshairPoint';
import config from 'config';
import utils from 'utils';
import './styles.scss';
import actions from 'actions';

class Map extends Component {
  state = {
    center: this.props.center,
    zoom: this.props.zoom,
    points: this.props.points,
    bounds: {
      ne: {
        lat: 0,
        lng: 0,
      },
      sw: {
        lat: 0,
        lng: 0,
      },
    },
    searchText: '',
    suggestions: [],
  };

  UNSAFE_componentWillReceiveProps({ center, zoom, points }) {
    this.setState({ center, zoom, points });
  }

  shouldComponentUpdate(newProp, newState) {
    const samePoints = JSON.stringify(newProp.points) === JSON.stringify(this.state.points);
    const sameCenter = JSON.stringify(newProp.center) === JSON.stringify(this.state.center);
    const sameZoom = newProp.zoom === this.state.zoom;
    const sameActions = newProp.actions === this.props.actions;
    const sameQuery = newState.searchText === this.state.searchText;
    const sameSuggestions =
      JSON.stringify(newState.suggestions) === JSON.stringify(this.state.suggestions);

    return (
      !samePoints || !sameCenter || !sameZoom || !sameActions || !sameQuery || !sameSuggestions
    );
  }

  _onMapChange = data => {
    this.setState(
      {
        zoom: data.zoom,
        bounds: data.bounds,
      },
      () => {
        if (typeof this.props.onChange === 'function') {
          this.props.onChange(data);
        }
      }
    );
  };

  onGoogleMapsReady = async ({ map, maps }) => {
    const { favouriteRegions } = await actions.favouriteRegions.getAll();

    favouriteRegions.forEach(favouriteRegion => {
      const paths = [
        { lat: favouriteRegion.ne.lat, lng: favouriteRegion.sw.lng },
        { lat: favouriteRegion.sw.lat, lng: favouriteRegion.sw.lng },
        { lat: favouriteRegion.sw.lat, lng: favouriteRegion.ne.lng },
        { lat: favouriteRegion.ne.lat, lng: favouriteRegion.ne.lng },
        { lat: favouriteRegion.ne.lat, lng: favouriteRegion.sw.lng },
      ];

      new maps.Polygon({
        paths,
        strokeColor: favouriteRegion.user.subscriptionLevel || 'blue',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#000',
        fillOpacity: 0,
        clickable: false,
      }).setMap(map);
    });

    this.googleMap = map;
    if (window.google && window.google.maps && window.google.maps.places) {
      this.placesService = new maps.places.PlacesService(map);
    }
  };

  timeout = null;
  handleSearchChange = event => {
    clearTimeout(this.timeout);

    const text = event.target.value;

    this.setState({ searchText: text });

    if (!text) {
      this.setState({ suggestions: [] });
      return;
    }

    this.timeout = setTimeout(() => {
      if (window.google && window.google.maps && window.google.maps.places) {
        const service = new window.google.maps.places.AutocompleteService();
        service.getPlacePredictions({ input: text }, predictions => {
          this.setState({ suggestions: predictions ? predictions.slice(0, 5) : [] });
        });
      }
    }, 500);
  };

  handleSuggestionClick = suggestion => {
    if (this.placesService) {
      this.placesService.getDetails({ placeId: suggestion.place_id }, (details, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const location = details.geometry.location;
          if (details.geometry.viewport) {
            this.setState({
              center: { lat: location.lat(), lng: location.lng() },
              // bounds: {
              zoom: 18,
              searchText: '',
              suggestions: [],
            });
          } else {
            this.setState({
              center: { lat: location.lat(), lng: location.lng() },
              zoom: 18,
              searchText: '',
              suggestions: [],
            });
          }
        }
      });
    }
  };

  render() {
    const { onPointClick, onPointHover, onSpotClick, selectedPhoto, selectedSpot, hideSpots } =
      this.props;
    const points = this.props.points.filter(
      x => x.id === selectedPhoto.id || utils.coors.contained(x, this.state.bounds)
    );

    let subPoints = [];

    const currentPoint = selectedPhoto;
    if (currentPoint && currentPoint.detective) {
      subPoints = currentPoint.detective.map(x => ({
        ...x,
        id: x.whoId,
        lat: x.position.latitude,
        lng: x.position.longitude,
        type: 'DETECTIVE',
        pointType: 'subPoint',
        _status: 99,
      }));
    }

    return (
      <div className="Map">
        <div className="actions">{this.props.actions}</div>

        <GoogleMap
          resetBoundsOnResize
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.onGoogleMapsReady}
          center={this.state.center}
          zoom={this.state.zoom}
          onChange={this._onMapChange}
          onClick={this.props.onClick}
          options={{
            tilt: 0,
            fullscreenControl: true,
            streetViewControl: true,
            mapTypeId: 'hybrid',
            styles: [
              {
                featureType: 'all',
                elementType: 'labels',
                stylers: [
                  {
                    visibility: '#on',
                  },
                ],
              },
            ],
          }}
          bootstrapURLKeys={{
            key: config.googleMaps.apiKey,
            libraries: 'places',
            language: 'en',
            region: 'en',
          }}
        >
          {subPoints.map(point => (
            <ColorPoint
              key={point.id}
              isSelected={point.id === selectedPhoto.id}
              {...point}
              onClick={() => onPointClick(point)}
            />
          ))}

          {points.map(point => {
            if (point.pointType === 'photo') {
              return (
                <ColorPoint
                  key={point.id}
                  {...point}
                  isSelected={point.id === selectedPhoto.id}
                  onClick={() => onPointClick(point)}
                  onHover={() => onPointHover(point)}
                />
              );
            }

            if (point.pointType === 'userPhoto') {
              return (
                <ColorPoint
                  key={point.id}
                  {...point}
                  isSelected={point.id === selectedPhoto.id}
                  onClick={() => onPointClick(point)}
                  onHover={() => onPointHover(point)}
                />
              );
            }

            if (point.pointType === 'crosshair') {
              return <CrosshairPoint key="crosshair" {...point} />;
            }

            if (point.pointType === 'spot' && !hideSpots) {
              return (
                <SpotPoint
                  key={point.id}
                  {...point}
                  isSelected={point.id === selectedSpot.id}
                  onClick={() => onSpotClick(point)}
                />
              );
            }

            if (point.pointType === 'area') {
              return (
                <ColorPoint key={point.id} {...point} isSelected={point.id === selectedPhoto.id} />
              );
            }

            if (point.pointType === 'citytour') {
              return (
                <ColorPoint key={point.id} {...point} isSelected={point.id === selectedPhoto.id} />
              );
            }

            return null;
          })}
        </GoogleMap>

        <div className="count">
          Visibles: {points.length} | Uns+Users:{' '}
          {this.props.points.length - this.props.spots.length - this.props.cityTours.length} |
          Spots: {this.props.spots.length} | CityTours: {this.props.cityTours.length}
        </div>

        <div className="searcher" style={{ opacity: this.state.searchText ? 1 : 0.8 }}>
          <input
            type="text"
            value={this.state.searchText}
            onChange={this.handleSearchChange}
            placeholder="Search places..."
          />
          {this.state.suggestions.length > 0 && (
            <div className="suggestions">
              {this.state.suggestions.map(suggestion => (
                <div
                  key={suggestion.place_id}
                  onClick={() => this.handleSuggestionClick(suggestion)}
                  style={{ padding: '5px', cursor: 'pointer' }}
                >
                  {suggestion.description}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Map.defaultProps = {
  center: { lat: 34.0442, lng: -118.2488 },
  zoom: 1,
  points: [],
  actions: [],
  onChange: () => {},
  onClick: () => {},
  onPointClick: () => {},
  onPointHover: () => {},
};

Map.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.number,
  points: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.arrayOf(PropTypes.element),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onPointClick: PropTypes.func,
  onPointHover: PropTypes.func,
};

export default Map;
