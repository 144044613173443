import React, { useEffect } from 'react';
import './styles.scss';

const PHOTO_TYPE = {
  UNSPLASH: 'UNSPLASH',
  USER: 'USER',
  DETECTIVE: 'DETECTIVE',
};

const EasyToAssign = ({ photo, spots, onYes, onNo }) => {
  const isUnsplash = photo.type === PHOTO_TYPE.UNSPLASH || photo.type === PHOTO_TYPE.DETECTIVE;

  useEffect(() => {
    const _onKeyPress = e => {
      if (e.key === 'Escape') {
        const button = document.querySelector('.EasyToAssign  button.reject');

        if (button) {
          button.click();
        }
      } else {
        const actionButton = document.querySelector(`.EasyToAssign [data-action-key="${e.key}"]`);
        // event.metaKey is CMD (CMD + 3, for example)
        if (actionButton && !e.metaKey && actionButton.click) {
          e.preventDefault();
          actionButton.click();
        }
      }
    };

    document.addEventListener('keydown', _onKeyPress, false);

    return () => {
      document.removeEventListener('keydown', _onKeyPress, false);
    };
  }, []);

  return (
    <div className="EasyToAssign">
      <div className="spots">
        <div className="spot">
          <img
            className="the-photo"
            src={
              isUnsplash
                ? photo.urls.regular
                : photo.url
                    .replace('.jpg', '.webp')
                    .replace(
                      'https://storage.googleapis.com/mari-a5cc7.appspot.com',
                      'https://statics.getnofilter.com'
                    )
            }
            alt="possible to be assigned"
          />

          <button className="reject" onClick={onNo}>
            NOP!
          </button>
        </div>

        {spots.map((spot, i) => (
          <div key={spot.id} className="spot">
            <button data-action-key={`${i + 1}`} className="approve" onClick={() => onYes(spot)}>
              THIS! ({i + 1})
            </button>

            <span>
              [{Math.trunc(spot.distance)}] {spot.name} - {spot.from}
            </span>

            {spot.photosHydrated.map(x => (
              <img key={x.id} src={x.urls.regular} alt="coming from unsplash" />
            ))}

            {spot.userPhotosHydrated.map(x => (
              <img
                key={x.id}
                src={x.url
                  .replace('.jpg', '.webp')
                  .replace(
                    'https://storage.googleapis.com/mari-a5cc7.appspot.com',
                    'https://statics.getnofilter.com'
                  )}
                alt="coming from user"
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EasyToAssign;
