import React, { Component } from 'react';
import actions from '../../actions';

class App extends Component {
  intervalRef = null;

  state = {
    count: 0,
  };

  componentDidMount() {
    this.intervalRef = setInterval(this.check, 200);
  }

  componentWillUnmount() {
    clearInterval(this.intervalRef);
  }

  check = () => {
    const count = actions.promises.getAll().length;
    this.setState({ count });
  };

  render() {
    const { count } = this.state;

    return <span style={{ color: count > 0 ? 'red' : 'green', fontWeight: 'bold' }}> {count}</span>;
  }
}

export default App;
