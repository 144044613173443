import api from 'api';
import config from 'config';

const getHydratedsWithLocation = ({ limit }) => {
  return api.userPhotos
    .getHydratedsWithLocation({ limit })
    .then(r => r.data.photos)
    .then(
      photos =>
        photos.map(x => ({
          ...x,
          url: `${config.firebaseBaseUrl}${x.file}`.replace('.jpg', '.webp'),
        })) // ?alt=media
    );
};

/**
 * @param {Object} Photo
 */
const reject = ({ id, cause, user: { id: userId } }) => {
  return api.userPhotos.reject({ id, cause, userId }).then(r => r.data);
};

/**
 * @param {Object} Photo
 * @param {Object} Spot
 */
const approve = ({ id, user: { id: userId } }, { id: spotId }) => {
  return api.userPhotos.approve({ id, spotId, userId }).then(r => r.data);
};

const actionsUserPhotos = {
  getHydratedsWithLocation,
  approve,
  reject,
};

export default actionsUserPhotos;
