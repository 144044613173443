import React, { Component } from 'react';
import produce from 'immer';
import actions from 'actions';
import Button from 'UI/Button';
import './styles.scss';

const LANGUAGES = [
  'ar',
  'be',
  'bg',
  'ca',
  'de',
  'es',
  'fr',
  'hi',
  'hr',
  'id',
  'is',
  'it',
  'ja',
  'ms',
  'nl',
  'pl',
  'pt',
  'ru',
  'tr',
  'vi',
  'zh-CN',
  'zh-TW',
];

class Translation extends Component {
  state = {
    isJSON: false,
    text: '',
    results: {},
    callsInProcess: 0,
    callsWithErrors: 0,
  };

  onTranslate = () => {
    const { text } = this.state;
    let isJSON = false;
    let theJSON = {};

    try {
      // eslint-disable-next-line no-eval
      eval(`theJSON = ${text}`);

      if (Object.keys(theJSON).length > 0) {
        isJSON = true;
      }
    } catch (error) {
      // Don't `return`. The error means that it is a string
    }

    this.setState(
      {
        results: {},
        isJSON,
        callsInProcess: 0,
        callsWithErrors: 0,
      },
      () => {
        if (isJSON) {
          this.translateJSON(theJSON);
        } else {
          this.translateString(text);
        }
      }
    );
  };

  translateJSON = theJSON => {
    LANGUAGES.forEach(language => {
      this.setState(
        produce(draft => {
          draft.results[language] = theJSON;
        })
      );

      Object.keys(theJSON).forEach(async key => {
        const text = theJSON[key];

        const query = {
          q: text,
          source: 'en',
          target: language,
        };

        this.setState(state => ({
          callsInProcess: state.callsInProcess + 1,
        }));

        try {
          const data = await actions.translation.translate(query);
          const translation = data.translations[0].translatedText;

          this.setState(
            produce(draft => {
              draft.results[language][key] = translation;
            })
          );
        } catch (error) {
          this.setState(state => ({
            callsWithErrors: state.callsWithErrors + 1,
          }));
        }

        this.setState(state => ({
          callsInProcess: state.callsInProcess - 1,
        }));
      });
    });
  };

  translateString = text => {
    LANGUAGES.forEach(language => {
      const data = {
        q: text,
        source: 'en',
        target: language,
      };

      this.setState(state => ({
        callsInProcess: state.callsInProcess + 1,
      }));

      actions.translation
        .translate(data)
        .then(data => {
          const translation = data.translations[0].translatedText;

          this.setState(
            produce(draft => {
              draft.results[language] = translation;
            })
          );
        })
        .catch(() => {
          this.setState(state => ({
            callsWithErrors: state.callsWithErrors + 1,
          }));
        })
        .finally(() => {
          this.setState(state => ({
            callsInProcess: state.callsInProcess - 1,
          }));
        });
    });
  };

  onTextChange = ({ target }) => {
    this.setState({ text: target.value });
  };

  render() {
    return (
      <div className="Translation content">
        <h1>
          In progress: {this.state.callsInProcess} | Errors: {this.state.callsWithErrors}
        </h1>

        <div>English:</div>
        <textarea value={this.state.text} onChange={this.onTextChange} />
        <Button onClick={this.onTranslate}>Translate!</Button>

        {LANGUAGES.map(language => (
          <div key={language} className="result">
            <div>{language}:</div>
            <textarea
              readOnly
              value={
                this.state.isJSON
                  ? JSON.stringify(this.state.results[language])
                  : this.state.results[language]
              }
            />
          </div>
        ))}
      </div>
    );
  }
}

export default Translation;
