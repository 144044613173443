import api from 'api';
import config from 'config';

const getAll = () => {
  return api.spots
    .getAll()
    .then(r => r.data)
    .then(spots => {
      return spots.map(spot => {
        if (!spot.userPhotos) {
          spot.userPhotos = [];
        }

        if (!spot.userPhotosHydrated) {
          spot.userPhotosHydrated = [];
        }

        spot.userPhotosHydrated = spot.userPhotosHydrated.map(x => ({
          ...x,
          url: `${config.firebaseBaseUrl}${x.file}`.replace('.jpg', '.webp'), //?alt=media
        }));

        return spot;
      });
    });
};

const getAllLight = () => {
  return api.spots.getAllLight().then(r => r.data);
};

const cacheGetHydratedById = {};

const getHydratedById = spotId => {
  if (cacheGetHydratedById[spotId]) {
    return cacheGetHydratedById[spotId];
  }

  cacheGetHydratedById[spotId] = api.spots
    .getHydratedById(spotId)
    .then(r => r.data)
    .then(({ spot }) => {
      if (!spot.userPhotosHydrated) {
        spot.userPhotosHydrated = [];
      }

      spot.userPhotosHydrated.forEach(x => {
        x.url = `${config.firebaseBaseUrl}${x.file}`.replace('.jpg', '.webp'); //?alt=media
      });

      return { spot };
    });

  return cacheGetHydratedById[spotId];
};

const create = spot => {
  return api.spots.create(spot).then(r => r.data);
};

const edit = (spot, newData) => {
  return api.spots.edit(spot, newData).then(r => r.data);
};

const assign = (spot, photo) => {
  return api.spots.assign(spot, photo).then(r => r.data);
};

const translateDescriptionById = spotId => {
  return api.spots.translateDescriptionById(spotId).then(r => r.data);
};

const actionsSpots = {
  getAll,
  getAllLight,
  getHydratedById,
  create,
  edit,
  assign,
  translateDescriptionById,
};

export default actionsSpots;
